<template>
  <div class="container">
    <div class="header">

    </div>
    <div class="main">
      <router-view ref="main"></router-view>
    </div>
    <div class="footer">
      <!-- <div>
        <img src="/static/images/home.png" alt="">
        <span>首页</span>
      </div> -->
      <div @click="goPath('/')">
        <img :src="crntpath=='/'?'/static/images/list_select.png':'/static/images/list.png'" alt="">
        <span :class="crntpath=='/'?'selected':''">商机列表</span>
      </div>
      <div @click="goPath('/mine')">
        <img :src="crntpath=='/mine'?'/static/images/mine_select.png':'/static/images/mine.png'" alt="">
        <span :class="crntpath=='/mine'?'selected':''">我的商机</span>
      </div>
      <div @click="goPath('/personal/reservation')">
        <img :src="crntpath=='/personal/reservation'?'/static/images/diy2_select.png':'/static/images/diy2.png'" alt="">
        <span :class="crntpath=='/personal/reservation'?'selected':''">商机订阅</span>
      </div>
      <div @click="goPath('/personal')">
        <img @click="goPath('/personal')" :src="crntpath=='/personal'?'/static/images/personal_select.png':'/static/images/personal.png'" alt="">
        <span :class="crntpath=='/personal'?'selected':''">个人中心</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crntpath:this.$route.path
    };
  },
  methods: {
    getUrlKey(name) {
      //获取url 参数
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
  created() { 
    // sessionStorage.setItem(this.HOST+'openid','oRQcouAWKQCVnI9GXIzcFhVgZk4Q')
    // this.resetSetItem("isLogin", 1);
    if (!sessionStorage.getItem(this.HOST + "openid")) {
        let code = this.getUrlKey("code");
        if (code) {
          this.$axios({
            url: "/login/login",
            data: {
              code: code,
            },
            showInfo:0
          }).then((res) => {
            if (res.status == 1) {
              sessionStorage.setItem(this.HOST+'openid', res.openid);
              this.resetSetItem("isLogin", 1);
            }
          });
        } else {
          this.$axios({
            url:'/login/getAppid',
            showInfo:0,
          }).then(res=>{
            let url =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+res.appid+"&redirect_uri=" +
            window.location.href +
            "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
          // let url = "http://auth.qlsww.com/auth/get?license=shangji";
          window.location.replace(url);
          })
          
        }
    }
  },
};
</script>
